<template>
  <div>
    <div v-if="application.isSigned()">
      <q-btn flat @click="getEdoc">
      <q-icon name="remove_red_eye" size="sm" title="View Contract"></q-icon>
      </q-btn>
    </div>
    <div>
      <a v-if="application.isApproved() && !application.isSigned()" :href="application.get('sign_link')" target="_blank">
      <q-icon name="assignment" size="sm" title="Sign Contract"></q-icon>
      </a>
    </div>
  </div>
</template>

<script>
// import Api from '../utils/api'

export default {
  name: 'ContractLink',
  props: {
    application: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      pdfUrl: ''
    }
  },
  methods: {
    async getEdoc () {
      var data = await this.$dispatch({
        action: 'applications/getEdoc',
        params: this.application.edoc().id(),
        message: 'Retrieving Contract'
      }, true)

      this.$emit('loaded', data)
    }
  }
}
</script>

<style>
</style>
