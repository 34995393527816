<template>
  <q-page padding>
  <div class="row">
      <div class="col q-pa-sm">
            <q-table title="Applications"
            :data="applications"
            :columns="columns"
            row-key="id"
            :loading="loading">
              <template v-slot:body="props">
                <q-tr :props="props">
                  <q-td key="status" :props="props">
                      {{ props.row.status }}
                  </q-td>
                  <q-td key="amount" :props="props">
                      {{ props.row.amount }}
                  </q-td>
                  <q-td key="received_at" :props="props">
                      {{ props.row.received_at }}
                  </q-td>
                  <q-td key="contract" :props="props">
                      <contract-link :application="getApp(props.row.id)" @loaded="setPdfContent"/>
                  </q-td>
                </q-tr>
              </template>
            </q-table>
      </div>
    </div>

    <iframe width="100%" height="1000px" v-if="pdfContent" :src="'data:application/pdf;base64,' + pdfContent"></iframe>

  </q-page>
</template>

<script>
import filters from '../mixins/filters'
import failedRequest from '../mixins/failed-request'
import ContractLink from '../components/ContractLink'

export default {
  name: 'PageApplications',
  meta: {
    title: 'Applications'
  },
  components: { ContractLink },
  mixins: [filters, failedRequest],
  data () {
    return {
      loading: true,
      columns: [
        { name: 'status', align: 'left', label: 'Status', field: 'status', sortable: false },
        { name: 'amount', align: 'left', label: 'Amount', field: 'amount', sortable: false },
        { name: 'received_at', align: 'left', label: 'Received at', field: 'received_at', sortable: false },
        { name: 'contract', align: 'left', label: 'Contract', field: 'contract', sortable: false }
      ],
      pdfContent: ''
    }
  },
  async mounted () {
    this.$q.loading.show({
      delay: 400
    })

    try {
      await this.$store.dispatch('applications/getApplications')
      this.loading = false
    } catch (e) {
      this.failedRequestNotification()
    }
    this.$q.loading.hide()
  },
  computed: {
    applications () {
      return this.$store.state.applications.applications ? this.$store.state.applications.applications.map(app => {
        return {
          id: app.id(),
          status: app.status(),
          received_at: this.date(app.get('created_at')),
          amount: this.currency(app.get('amount'))
        }
      }) : []
    }
  },
  methods: {
    getApp (appId) {
      return this.$store.state.applications.applications.find(app => app.id() === appId)
    },
    setPdfContent (data) {
      this.pdfContent = data
    },
    navToApp (applicationId) {
      this.$router.replace({
        name: 'application',
        params: {
          id: applicationId
        }
      })
    }
  }
}
</script>

<style>
</style>
